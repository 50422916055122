<template>
  <div class="downloads">
    <div class="main-title">
      <p>
        {{ this.$i18n.locale == 'ar' ? 'مركز التحميلات' : 'Downloads Center' }}
      </p>
    </div>
    <div class="banner">
      <div class="row align-items-center">
        <div class="col-md-6 text-content p-0">
          <h1 class="title">
            {{
              this.$i18n.locale == 'ar'
                ? `اطلع على المعلومات من الملفات`
                : `See information from files`
            }}
          </h1>
          <p class="mt-4">
            {{ $t('DownloadsCenterDescription') }}
          </p>
        </div>
        <div class="col-md-6 d-flex justify-content-center img-content">
          <img
            src="@/assets/img/downloads-center/download.png"
            alt="UsingPolicy"
          />
        </div>
      </div>
    </div>
    <div class="row mb-5 info">
      <div class="container">
        <ul class="nav nav-pills mb-2" id="downloads-tab" role="tablist">
          <li
            class="nav-item active"
            role="presentation"
            v-for="item in DownloadsCenterCatg"
            :key="item.id"
          >
            <button
              @click="getDownloadsCenterData(item.id)"
              data-index="download-1"
              :class="[
                'nav-link py-3 d-inline-flex',
                { active: item.id === 1 },
              ]"
              id="v-pills-download-1-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-download-1"
              type="button"
              role="tab"
              aria-controls="v-pills-download-1"
              aria-selected="true"
            >
              <span class="fs-sm-4">{{ item.name }}</span>
            </button>
          </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-download-1"
            role="tabpanel"
            aria-labelledby="v-pills-download-1-tab"
          >
            <div class="row">
              <div
                class="col-md-6"
                v-for="item in TabData"
                :key="item.id"
                v-if="TabData.length > 0"
              >
                <div
                  class="download-card d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img :src="getFileIcon(item.path)" height="40px" width="40px" alt="file">
                    <div class="file-name">{{ item.name }}</div>
                  </div>
                  <div @click="downloadFile(item.path)" class="pointer">
                    <!-- <a target="_blank" rel="noopener noreferrer" :href="item.path" download="">
                    `https://demo.etmaam.com.sa/img/Packages-center.1466cdb3.png`
                    -->
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.14142 10.3586C7.06332 10.4367 6.93668 10.4367 6.85858 10.3586L2.76396 6.26396C2.68682 6.18682 2.68573 6.0621 2.7615 5.98362L3.70862 5.00268C3.7863 4.92223 3.91485 4.9211 3.99392 5.00017L5.78358 6.78983C5.90957 6.91582 6.125 6.82659 6.125 6.64841V0.2C6.125 0.0895427 6.21454 0 6.325 0H7.675C7.78546 0 7.875 0.0895431 7.875 0.2V6.64841C7.875 6.82659 8.09043 6.91582 8.21642 6.78983L10.0061 5.00017C10.0852 4.9211 10.2137 4.92223 10.2914 5.00268L11.2385 5.98362C11.3143 6.0621 11.3132 6.18682 11.236 6.26396L7.14142 10.3586ZM1.75 14C1.26875 14 0.856917 13.8288 0.5145 13.4864C0.172083 13.144 0.000583333 12.7318 0 12.25V9.825C0 9.71454 0.0895431 9.625 0.2 9.625H1.55C1.66046 9.625 1.75 9.71454 1.75 9.825V12.05C1.75 12.1605 1.83954 12.25 1.95 12.25H12.05C12.1605 12.25 12.25 12.1605 12.25 12.05V9.825C12.25 9.71454 12.3395 9.625 12.45 9.625H13.8C13.9105 9.625 14 9.71454 14 9.825V12.25C14 12.7312 13.8288 13.1434 13.4864 13.4864C13.144 13.8294 12.7318 14.0006 12.25 14H1.75Z"
                        fill="#052C57"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <NoDataToShow
                v-else
                style="margin-top: 50px; margin-bottom: 35px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner/>
  </div>
</template>

<script>
import Spinner from '../Global/Spinner.vue'
import NoDataToShow from '../Global/NoDataToShow.vue'
import axios from 'axios'

export default {
  name: 'IndexDownloadCenter Page',
  data () {
    return {
      DownloadsCenterCatg: [],
      TabData: [],
      activeIndex: 0,
      title: '',
      description: '',
      img: '',
      card_1_title: '',
      card_1_description: '',
      card_1_photo: '',
      card_2_title: '',
      card_2_description: '',
      card_2_photo: '',
      card_3_title: '',
      card_3_description: '',
      card_3_photo: '',
    }
  },
  components: {
    NoDataToShow,
    Spinner,
  },

  mounted () {
    this.$store.dispatch('DownloadsCenterCategories').then((r) => {
      this.DownloadsCenterCatg = this.$store.state.DownloadsCenterCatg
    })

    this.$store.dispatch('DownloadsCenterData', 1).then((r) => {
      this.TabData = this.$store.state.DownloadCenterData
    })

    this.$store.dispatch('GetStaticContent').then((r) => {
      this.handleStaticAbout(this.$store.state.StaticContent)
    })

    this.activeIndex = 0
  },

  methods: {
    getFileIcon (filename) {
      if (filename.endsWith('.pdf')) {
        return require('@/assets/img/downloads-center/pdf.svg')
      } else if (filename.endsWith('.docx')) {
        return require('@/assets/img/downloads-center/word.svg')
      } else if (filename.endsWith('.zip') || filename.endsWith('.rar')) {
        return require('@/assets/img/downloads-center/zip.svg')
      } else {
        return require('@/assets/img/downloads-center/image.svg')
      }
    },
  handleStaticAbout (StaticContent) {
    StaticContent.forEach((item) => {
      if (item.key == 'about_etmaam') {
        this.title = item.value
      }
      if (item.key == 'about_etmaam_description') {
        this.description = item.value
      }
      if (item.key == 'about_etmaam_photo') {
        this.img = item.value
      }
      if (item.key == 'card_1_title') {
        this.card_1_title = item.value
      }
      if (item.key == 'card_1_description') {
        this.card_1_description = item.value
      }
      if (item.key == 'card_1_photo') {
        this.card_1_photo = item.value
      }
      if (item.key == 'card_2_title') {
        this.card_2_title = item.value
      }
      if (item.key == 'card_2_description') {
        this.card_2_description = item.value
      }
      if (item.key == 'card_2_photo') {
        this.card_2_photo = item.value
      }
      if (item.key == 'card_3_title') {
        this.card_3_title = item.value
      }
      if (item.key == 'card_3_description') {
        this.card_3_description = item.value
      }
      if (item.key == 'card_3_photo') {
        this.card_3_photo = item.value
      }
    })
  },
  async downloadFile (path) {
    try {
      const response = await axios.get(path, {
        responseType: 'blob', // Important for file download
      })

      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]))

      // Create a link element
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `file.${path.slice(path.length - 3, path.length)}`
      ) // Specify the filename

      // Append to the body and trigger the click
      document.body.appendChild(link)
      link.click()

      // Clean up
      link.parentNode.removeChild(link)
    } catch (error) {
      console.error('Error downloading the file:', error)
    }
  },
  getDownloadsCenterData (id) {
    this.$store.commit('SET_Spinner', true)
    this.$store.dispatch('DownloadsCenterData', id).then((r) => {
      this.TabData = this.$store.state.DownloadCenterData
      this.$store.commit('SET_Spinner', false)
    })
  },
}
,
}

</script>

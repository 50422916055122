<template>
    <div class="logo-spinner" v-if="$store.state.Spinner">
        <img src="../../assets/img/global/etmaam-logo.svg" alt="">
        <p class="mt-3">{{
        this.$i18n.locale == 'ar' ?
        'جاري تنفيذ الطلب...'
        :
        'The request is being executed..'
        }}</p>
    </div>
</template>
<script>
export default {
data(){

}

};
</script>
